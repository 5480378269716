import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/headerservices"

const Services = () => (
  <Layout>
    <SEO title="Services" />

    <Header />
    <section className="section bg-primary">
      <div className="container-fluid py-4">
        <div className="row">
          <div className="col">
            <h1 className="h2 my-2 font-weight-bold text-light">
              OUR SERVICES
            </h1>
          </div>
          <div className="col-auto">
            <a className="btn btn-sm btn-outline-light">
              WHAT DO STUDENTS GAIN FROM EXCHANGE PROGRAM
            </a>
          </div>
        </div>
      </div>
    </section>

    <section className="section py-8  px-8 pb-9">
      <div className="container">
        <div className="py-8">
          <div className="row row-grid justify-content-between align-items-center">
            <div className="col-auto">
              <h5 className="h2 text-center font-weight-bold text-primary text-uppercase">
                OUR SERVICES
              </h5>
              <p className="my-4">
                <ul className="list pt-2">
                  <li className="list-item text-primary-alt lead">Study in Rwanda supports international student recruitment</li>
                  <p className="pt-2">We provide requisite technology, this includes; recruitment management software, e-newsletters, university programs and courses offered automatically generated student’s identification cards, available intakes, application fee automatically generated invoices and all other necessary information. Study in Rwanda program provide global solutions.</p>
                  <li className="text-primary-alt lead">Study in Rwanda supports exchange programs</li>
                  <p className="pt-2">Student exchange programs to Rwanda and abroad gives students opportunity to tour and experience the world. Our foreign exchange programs are life changing experiences for students as they get to join highly accredited schools. Institutions in partnership with Study in Rwanda get to participate in the exchange programs by receiving students from Rwanda or sending students to Rwanda.</p>
                  <li className="text-primary-alt lead">
                  Study in Rwanda supports partnerships
                  </li>
                  <p className="pt-2">Study in Rwanda partnerships help to further improve the quality of education in Rwanda. Partnerships allow foreign Higher Learning Institutions to have access on local market to promote creativity and advancement in a multicultural environment. Our Partnerships also allow introduction of high demand courses in Africa, and introduction of international curriculum in Rwanda. </p>
                  <li className="text-primary-alt lead">
                  Education fairs
                  </li>
                  <p className="pt-2">Study in Rwanda’s mission is to promote international education services in Africa! We aim at improving our education sector to provide world-class services.</p>
                  <p>To enable this, Study in Rwanda attends and hosts education fairs to promote international tertiary education. Career fairs bring together potential students, investors and parents ready to apply for admissions to world-class universities for undergraduate and postgraduate programs. This helps us to realize the need in the education market and find solutions.</p>
                  <li className="text-primary-alt lead">Study in Rwanda Institution National Ranking</li>
                  <p className="pt-2">Study in Rwanda will conduct Rankings for our partners this event will boost the education sector.  This way Study in Rwanda will realize the gaps that require to be filled in the Education sector.</p>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div className="py-8">
          <div className="row row-grid justify-content-center align-items-center">
            <div className="col-auto">
              <h5 className="h2 text-center font-weight-bold text-primary text-uppercase">
                Having trouble Accessing your target market?
              </h5>
              <p className=" my-4">
                Study in Rwanda program has examined the education sector
                internally. We work with investors to ensure they enjoy maximum
                benefits from our intervention. Our program will provide
                information on trends identified in similar investment flows
                that favor the region.
              </p>
              <p className=" my-4">
                Study in Rwanda will also help the investor in the evaluation
                process and most importantly the investor will enjoy consultancy
                services. The program will provide credible, reliable and useful
                opinions in terms of:
              </p>
              <p className="my-4">
                <ul>
                  <li>Possible relevance of the investment</li>
                  <li>Sustainability</li>
                  <li>
                    Impact, efficiency and effectiveness of the investment
                  </li>
                  <li>
                    Setting up, managing implementing the business idea and it’s
                    the possible outcome
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <div className="">
          <div className="row justify-content-center">
            <div className="col-lg-10">
            <img
                    alt="Image placeholder"
                    src={require("../images/downloads/3P7A8228.jpg")}
                    className="img-fluid shadow rounded"
                  />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section py-8  px-8 pb-9">
      <div className="container">
        <div className="py-8">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <h5 className="h3 text-uppercase text-primary font-weight-bold text-center">
                Your top five security investments for the biggest returns!
              </h5>
              <p className="text-center font-weight-bold">
                Are you looking for an investment idea in the education sector?
                We help you gain from our perspective!
              </p>
              <p className="my-4">
                <ul>
                  <li>Establish more universities in Rwanda</li>
                  <li>Offer scholarships to students</li>
                  <li>Invest in field Research and exchange programs</li>
                  <li>School infrastructure investment </li>
                  <li>Invest in student accommodation space</li>
                </ul>
              </p>
              <p className="my-4">
                Would you like to sponsor a needy student through school? Become
                a sponsor!
              </p>
              <p className="my-4">
                For more information talk to us: <a className="text-primary" href="mailto:info@studyinrwanda.rw">info@studyinrwanda.rw{" "}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Services
